<div class="checkbox mobile-menu-toggle">
    <input id="id-mobile-menu-toggle" class="checkbox__control" type="checkbox" name="checkbox-default" 
        role="button" 
        aria-label="Toggle site navigation"
        aria-expanded="false" 
        aria-haspopup="true"
        aria-controls="site-nav-menu-id"
    >
    <span class="checkbox__icon mobile-menu-toggle__icon" hidden="">
        <svg class="checkbox__unchecked" height="18" width="18" aria-hidden="true">
            <use href="#icon-menu-20"></use>
        </svg>
        <svg class="checkbox__checked" height="18" width="18" aria-hidden="true">
            <use href="#icon-close-20"></use>
        </svg>
    </span>

    <nav id="site-nav-menu-id" class="mobile" aria-labelledby="site-nav-title" aria-hidden="true">
        <div class="site-nav-box">
            <h2 id="site-nav-title">Main Navigation</h2>
            <site-links/>
        </div>
    </nav>
</div>

class {
    onCreate() {
        this.boundMenuClick = this.click.bind(this);
        this.elMenuBtn = null;
        this.elSiteNav = null;
    }
    onMount() {
        this.elMenuBtn = document.getElementById('id-mobile-menu-toggle');
        this.elSiteNav = document.getElementById('site-nav-menu-id');
        this.elMenuBtn.addEventListener("click", this.boundMenuClick);
    }
    onDestroy() {
        this.elMenuBtn.removeEventListener("click", this.boundMenuClick);
    }
    click(e) {
        this.elMenuBtn.setAttribute('aria-expanded', !!e.target.checked);
        this.elSiteNav.setAttribute('aria-hidden', !e.target.checked);
    }
}

style {
    .mobile-menu-toggle {
        position: static;
        border: 1px solid var(--anchor-color);
        border-radius: 2px;
        width: 28px;
        height: 28px;
        margin-top: 2px;
    }

    #id-mobile-menu-toggle {
        height: 28px;
        min-width: 28px;
        width: 28px;
    }

    .mobile-menu-toggle svg {
        width: 26px;
        height: 26px;
        color: var(--anchor-color);
    }

    .mobile-menu-toggle input ~ nav.mobile {
        border: 1px solid var(--color-stroke-subtle);
        display: none;
        position: absolute;
        background-color: var(--color-background-elevated);
        border-radius: 5px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
        margin: 0;
        padding: 8px;
        padding-bottom: 16px;
        width: calc(100% - 32px);
    }

    span.mobile-menu-toggle__icon {
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
    }

    .mobile-menu-toggle input:checked ~ .mobile-menu-toggle__icon {
        background-color: #fff;
    }

    .mobile-menu-toggle input:checked ~ nav.mobile {
        display: block;
        z-index: 1;
        top: 54px;
    }

    nav.mobile {
        margin-top: 2px;
    }

    nav.mobile li {
        border-bottom: 1px solid var(--color-stroke-subtle);
    }

    nav.mobile h2 {
        margin-block-start: 0;
    }

    nav.mobile a {
        display: block;
        color: var(--anchor-color);
        font-style: normal;
        text-transform: uppercase;
        text-decoration: none;
        padding: 8px;
    }

    nav.mobile a:visited {
        color: var(--anchor-color);
    }

    nav.mobile li:first-child {
        border-top: 1px solid var(--color-stroke-subtle);
    }

    nav.mobile svg.icon--menu {
        visibility: visible;
        opacity: 1;
        transition: all 200ms linear;
    }

    nav.mobile svg.icon--close {
        position: absolute;
        left: 10%;
        visibility: hidden;
        opacity: 0;
        transition: all 200ms linear;
    }

    @media screen and (min-width: 512px) {
        .mobile-menu-toggle {
            display: none;
        }

        .mobile-menu-toggle input ~ nav.mobile {
            width: calc(100% - 72px);
        }

        nav.header-menu ul {
            display: flex;
            gap: 4%;
        }
        
        nav.mobile {
            display: none;
        }
    }
}